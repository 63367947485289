<template>
  <ul>
    <li
      v-for="(f,i) in fiche"
      :key="i"
    >
      {{ f }}
    </li>
  </ul>
</template>
      
      <script>
      import {mapGetters } from 'vuex'
      export default {
          props: {object: {type: Object, required: true}},
          data(){
              return{
      
              }
          },
          methods: {
             
          },
          computed: {
              ...mapGetters({
                fiches: 'sanitaire/ficheAnalyses'
              }),
              fiche(){
                let f = []
                this.object.fiche.split(',').forEach(i => {
                  let c = this.fiches.find(item => item.uid === i)
                  if(c) f.push(c.libelle)
                })
                return f
              }
          }
      }
      </script>
      
      <style>
      
      </style>